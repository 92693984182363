import {
    Badge,
    Group,
    Title,
    Text,
    Card,
    SimpleGrid,
    Container,
    rem,
    useMantineTheme,
    Stack,
    Loader,
    Image,
    Box,
    Grid,
    Chip,
  } from '@mantine/core';
  import { IconGauge, IconUser, IconCookie } from '@tabler/icons-react';
  import classes from './../../styles/Products.module.css';
import { ProductCard } from '../Cards';
import useAxios from 'axios-hooks'
import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { searchSortedData } from '../../lib/sort';
import { useMediaQuery } from '@mantine/hooks';
import axios from 'axios';
  
export function Products() {
    const theme = useMantineTheme();
    let [searchParams, setSearchParams] = useSearchParams();
    let [filterdAllProducts, setFiltredAllProducts] = useState<any>([]);
    const [{ data: allProducts, loading, error }, refetch] = useAxios(
      'https://api2.rumberio.store/api/posting-products/'
    )
    const matches = useMediaQuery('(max-width: 36em)');

    let [allCategories, setAllCategories] = useState<any[]>([]);
    let [category, setCategory] = useState<any>([]);

    useEffect(() => {
      axios.get(`https://api2.rumberio.store/api/category`)
      .then(({data}) => {
        setAllCategories(data)
      })
      .catch((error) => console.log(error));
    }, [])

    useEffect(() => {
      if (allProducts && allProducts.length >= 0) {
        setFiltredAllProducts(allProducts)
      }
    }, [allProducts])

    useEffect(() => {
      const search = searchParams.get("search");
      if (search || category.length > 0) {
        if (category && category.length > 0 && search && search !== "") {
          setSearchParams({search, category})
          let newData = allProducts?.filter((item: any) => {
            for (let i = 0; i < category.length; i++) {
              const element = category[i];
              if (item.categories.includes(element)) return true
            }
          })
          newData = searchSortedData(newData, ["name"], search)
          setFiltredAllProducts(newData)
        } else if (search && search !== "") {
          setSearchParams({search})
          let newData = searchSortedData(allProducts, ["name"], search)
          setFiltredAllProducts(newData)
        } else if (category && category.length > 0) {
          setSearchParams({category})
          let newData = allProducts?.filter((item: any) => {
            for (let i = 0; i < category.length; i++) {
              const element = category[i];
              if (item.categories.includes(element)) return true
            }
          })
          setFiltredAllProducts(newData)
        } else {
          setSearchParams()
        }
      } else {
        setFiltredAllProducts(allProducts)
      }

      
    }, [searchParams.get("search"), category])

    if (error) return <p>Error!</p>
    
    return (
      <Container size="lg" py="xl" >
        <Title order={2} className={classes.title} ta="center" mt="sm">
          نوفر لكم أحدث المنتجات العالمية بسعر يناسب الجميع 🤩
        </Title>
  
        <Text mb={20} c="dimmed" className={classes.description} ta="center" mt="md" fw={"bold"}>تصفح قائمة المنتجات الأكثر رواجا</Text>
  
        {loading
          ? <Stack align='center' justify='center' mt={50}>
              <Loader color="#ff914d" size="md" type="bars" />
            </Stack>
          : null
        }

        
        <Box my={50}>
          <Chip.Group multiple={true} value={category} onChange={setCategory}>
            <Grid>
              {allCategories.map((item: any, index) => (
                <Grid.Col key={index} span={{base: 6, sm: 4, md: 3, xl: 2}}>
                  <Chip 
                    value={item.name} icon={<></>} color='#ff914d'
                    variant="outline" radius={5}
                    styles={{
                      label: { padding: 10, width: "100%", height: 70 },
                      iconWrapper: { display: 'none' }
                    }}
                  >
                    <Group justify='flex-start' gap={10} w={"100%"}>
                      <Image src={`https://api2.rumberio.store/images/${item.image}`} alt={item.image} w={50} h={50} />
                      <Text>{item.name}</Text>
                    </Group>
                  </Chip>
                </Grid.Col>
              ))}
            </Grid>
          </Chip.Group>
        </Box>
        
        
        {filterdAllProducts && filterdAllProducts?.length > 0
          ? <SimpleGrid cols={{ base: 1, sm: 2, md: 3, lg: 4, xl: 5 }} spacing="xl" mt={50}>
              {filterdAllProducts?.map((item: any, index: number) => (
                  <ProductCard
                    key={index}
                    id={item._id}
                    title={item?.name}
                    image={`https://api2.rumberio.store/images/${item?.thumbnail}`}
                    price={item?.price }
                    priceAfterDiscount={item.priceAfterDiscount}
                  />
              ))}
            </SimpleGrid>
          : <Stack align='center' justify='center' mt={50}>
              <Image src={"/shopping-trolley.png"} alt={"shopping trolley"} h={"100px"} w={"100px"} fit='contain' />
            </Stack>
        }
      </Container>
    );
  }