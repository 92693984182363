import { Card, Image, Text, Group, Badge, Button, ActionIcon } from '@mantine/core';
import classes from './../../styles/ProductCard.module.css';
import { Link } from 'react-router-dom';


interface Props {
  id: string;
  title: string;
  image: string;
  price: number;
  priceAfterDiscount: number;
}

export function ProductCard({ id, image, title, price, priceAfterDiscount }: Props) {

  return (
    <Card withBorder radius="md" p="md" className={classes.card}>
      <Card.Section style={{position: 'relative'}}>
        <Image src={image} alt={title} width={"100%"} mah={220} fit={'cover'}  />
        {priceAfterDiscount > 0
          ? <Badge variant="filled" color={"#ff914d"} size='lg' className={classes.badge} radius={0}>
              تخفيض
          </Badge>
          : null
        }
      </Card.Section>

      <Card.Section className={classes.section} mt="md">
          <Text fz="lg" fw={500} component={Link} to={`/product/${id}`} className={classes.link}>
            {title}
          </Text>
      </Card.Section>
      
      <Card.Section className={classes.section}>
        <Group gap={10} mt={20}>
            <Text size='md' fw={'bold'} className={classes.label} c="#ff914d">
              {priceAfterDiscount > 0 ? priceAfterDiscount : price} د.ج
            </Text>
            {priceAfterDiscount > 0 
              ? <Text size='sm' fw={'bold'} className={classes.label} c="dimmed" td="line-through">
                  {price} د.ج
                </Text>
              : null
            }
        </Group>
      </Card.Section>
      
      <Group flex={"auto"} mt={"xs"} align='flex-end'>
        <Button 
          radius="md" style={{ flex: 1 }} color='#ff914d' id='btn'
          component={Link} to={`/product/${id}`} className={classes.button}
        >
          اشتري الآن
        </Button>
      </Group>
    </Card>
  );
}