import React, { useEffect } from 'react';
import { HeaderSimple, Topbar } from '../components/Navbar';
import { Products } from '../components/Sections';
import { Footer } from '../components/Sections/Footer';
import { Box, Container, Group, Image, Text } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

export function WhoAreWe () {
    const matches = useMediaQuery('(max-width: 36em)');

    return (
        <>
            <HeaderSimple />
            <Container size={'xl'}>
                <Group justify='center' align='center' w={"100%"} mt={ matches ? 180 : 130}>
                    <Box p={20} my={50} bg={"#f1f1f1"}>
                        <Text size='xl' fw={'bold'} >من نحن ؟</Text>
                        <Text size='md' mb={7}>هذا المتجر بوابتك الجديدة للتسوق الكترونيا بشكل سهل وبسيط.</Text>
                        <Text size='md' >نوفر لك منتجات متعدّدة ذات جودة عالية لتختار منها الأفضل وبسعر تنافسي لن تجده في أي مكان آخر. التسوق معنا عملية" ممتعة وآمنة. ونوفر لك كل ما تحتاجه من التسهيلات سواء في إختيار المنتج أو في عملية الدفع أو في عملية الشحن. مهمتنا هي سعادتكم نعتمد على ثلاث مبادئ رئيسية تشكل أساس نجاحنا وهي توفير أفضل وأجود المنتجات جودة الخدمة التي لا تنافس بالإضافة الى ارضاء جميع زبائننا بدون استثناء بالإعتماد على هذه المبادئ الرئيسية نسعى لإحداث ثورة في تجربة التسوق في الجزائر وإدخال مزيد من المتعة والسعادة لحياتكم.</Text>
                        
                        <Text size='xl' fw={'bold'} mt={30} >لماذا تختارنا؟</Text>
                        <Text size='md' mb={7}>تجربة تسوق ممتعة سهلة وبسيطة.</Text>
                        <Text size='md' mb={7}>منتجات مضمونة بجودة عالية تمر على مرحلة مراجعة دقيقة.</Text>
                        <Text size='md' mb={7}>أفضل الأسعار في السوق مع تقديم عروض وتخفيضات مستمرة.</Text>
                        <Text size='md' mb={7}>الدفع عند الإستلام (خلص كي توصلك السلعة وتعجبك) رضا مضمون 100%.</Text>
                        <Text size='md' mb={7}>خدمة زبائن درجة أولى قبل وبعد البيع. دائما مستعدين لخدمتكم.</Text>
                        <Text size='md' mb={7}>فريق جاهز للدعم وتقديم المساعدة لتوفير كل الدعم والمساعدة لكم والإجابة على كل إستفساراتكم يمكنكم الاتصال بنا على الرقم التالي: 0795110477 سنكون سعداء بتواصلكم وتقديم كل المساعدة لكم.</Text>
                        <Text size='md' mb={7}></Text>
                        <Text size='md' mb={7}></Text>
                    </Box>
                </Group>
            </Container>
            <Footer/>
        </>
    );
}