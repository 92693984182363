import { useState } from 'react';
import { Container, Group, Burger, Image, ActionIcon, rem, TextInput, CloseButton, Box, Text } from '@mantine/core';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import classes from './../../styles/HeaderSimple.module.css';


export function Topbar() {
  const matches = useMediaQuery('(max-width: 36em)');
  

  return (
    <Box bg={"#ff914d"} h={35} mb={10} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <Text size='md' ta={'center'}>عروض و تخفيضات على جميع المنتجات 🤩</Text>
    </Box>
  );
}