import React, { useEffect } from 'react';
import { HeaderSimple, Topbar } from '../components/Navbar';
import { Products } from '../components/Sections';
import { Footer } from '../components/Sections/Footer';
import { Box, Group, Image, Text } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

export function PaymentDeliveryPolicy () {
    const matches = useMediaQuery('(max-width: 36em)');

    return (
        <>
            <HeaderSimple />
            <Group justify='center' align='center' w={"100%"} mt={ matches ? 180 : 130}>
                <Box p={20} my={50} bg={"#f1f1f1"}>
                    <Text size='lg' fw={'bold'} >سياسة الدفع و التوصيل:</Text>

                    <Text size='md' mb={10}>
                        نوفر لكم منتجات ذات جودة عالية لتختار منها الأفضل و بسعر لن تجده في أي مكان آخر، التسوق معنا عملية ممتعة و آمنة.
                    </Text>
                    <Text size='md' mb={10}>
                        منتجاتنا مضمونة بجودة عالية تمر على مرحلة مراجعة دقيقة ، و رغم ذلك  يحق للزبون فحص طلبيته عند استلامها قبل الدفع. في حالة ما وجد المنتج معطوبا او مكسورا او فيه خلل ، فإننا نلتزم بتعويض زبائننا في الحال.
                    </Text>
                    <Text size='md'>
                        استمتعوا بتجربة تسوق ممتعة و سهلة بالإضافة إلى خدمة توصيل سريع و الدفع عند الاستلام (كي تلحقك سلعتك و تعجبك تخلصها) تتراوح بين 2 و 4 أيام لأغلب ولايات الوطن.
                    </Text>
                </Box>
            </Group>
            <Footer/>
        </>
    );
}