import React, { useEffect } from 'react';
import { HeaderSimple, Topbar } from '../components/Navbar';
import { Products } from '../components/Sections';
import { Footer } from '../components/Sections/Footer';
import { Box, Group, Image, Text } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

export function PrivacyPolicy () {
    const matches = useMediaQuery('(max-width: 36em)');

    return (
        <>
            <HeaderSimple />
            <Group justify='center' align='center' w={"100%"} mt={ matches ? 180 : 130}>
                <Box p={20} my={50} bg={"#f1f1f1"}>
                    <Text size='lg' fw={'bold'} >سياسة الخصوصية :</Text>

                    <Text size='md'>
                        تنتهج سياسة الدفع عند الاستلام، و لذلك فإننا نحتفظ ببيانات زبائننا حتى يتم تسليم طلباتهم، ولا نشاركها مع أي طرف آخر.
                    </Text>
                </Box>
            </Group>
            <Footer/>
        </>
    );
}