import React, { useEffect } from 'react';
import { HeaderSimple, Topbar } from '../components/Navbar';
import { Products } from '../components/Sections';
import { Footer } from '../components/Sections/Footer';
import { Image } from '@mantine/core';
import { useMediaQuery, useWindowScroll } from '@mantine/hooks';

export function Home () {
    const matchesXS = useMediaQuery('(max-width: 36em)');
    const matchesSM = useMediaQuery('(max-width: 48em)');
    const matchesMD = useMediaQuery('(max-width: 62em)');
    const matchesLG = useMediaQuery('(max-width: 75em)');
    const matchesXL = useMediaQuery('(max-width: 88em)');
    const [scroll, scrollTo] = useWindowScroll();
    
    useEffect(() => {
        scrollTo({y: 0})
    }, [])

    return (
        <>
            <HeaderSimple />
            <Image 
                src={"/Banner.webp"} alt={"Banner"} fit='fill'
                w={"100%"} h={matchesXS ? 215 : matchesSM ? 370 : matchesMD ? 485 : matchesLG ? 670 : "100%"}
                mt={ matchesXS ? 180 : 130}
            />
            <Products />
            <Footer/>
        </>
    );
}