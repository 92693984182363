import './styles/App.css';
import { Route, Routes } from 'react-router-dom';
import { Home, Product, LandingProduct, Order, PrivacyPolicy, PaymentDeliveryPolicy, WhoAreWe } from './pages';
import ReactPixel from 'react-facebook-pixel';
import { useEffect, useState } from 'react';
import axios from 'axios';

function App() {
    const [facebookToken, setFacebookToken] = useState("")
    
    useEffect(() => {
        axios.get(`https://api2.rumberio.store/api/pixels`)
        .then(({data}) => {
            const advancedMatching = undefined;
            data?.map((item: any, index: number) => {
                if (item?.apiKey !== "" && item?.apiKey.length > 10) {
                    ReactPixel.init(item?.apiKey, advancedMatching, {
                        autoConfig: true,
                        debug: false
                    });
                }
            })
            ReactPixel.pageView();
        })
        .catch((error) => console.log(error));
    }, [])

    // useEffect(() => {
    //     if (facebookToken !== "") {
    //         ReactPixel.init(facebookToken);
    //         ReactPixel.pageView();
    //         // ReactPixel.track()
    //     }
    // }, [facebookToken])

    return (
        <Routes>
            <Route index path="/" element={<Home />} />
            <Route index path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route index path="/Payment-delivery-policy" element={<PaymentDeliveryPolicy />} />
            <Route index path="/who-are-we" element={<WhoAreWe />} />
            <Route index path="/product/:id" element={<Product />} />
            <Route index path="/:id" element={<LandingProduct />} />
            <Route index path="/order/:status/:type?/:id?/:isMany?" element={<Order />} />
        </Routes>
    );
}

export default App;
