import { Text, Container, Group, Image, useMantineTheme, Title, Grid } from '@mantine/core';
import { IconMapPinFilled, IconPhoneFilled, IconMailFilled } from '@tabler/icons-react';
import classes from './../../styles/Footer.module.css';
import { Link, useNavigate } from 'react-router-dom';


export function Footer() {
    const theme = useMantineTheme();
    const navigate = useNavigate();

    return (
        <footer className={classes.footer}>
            <Container className={classes.inner}>
                <div className={classes.logo} >
                    <Image src={"/logo.png"} alt='logo' height={60} fit='contain' style={{cursor: "pointer"}} onClick={() => navigate("/")}/>
                    <Text size="xs" c="dimmed" mt={10} className={classes.description}>
                        المكان الأمثل للحصول على منتجات مميزة بأسعار مغرية
                    </Text>
                    
                    <Text c="dimmed" size="sm" mt={10}>
                        جميع الحقوق محفوظة © {new Date().getFullYear()}
                    </Text>
                </div>
                
                <Grid className={classes.groups} >
                    <Grid.Col span={{base: 6}}>
                        <Title order={5} className={classes.title}>الشروط والسياسات</Title>
                        <Group gap={5} mt={20}>
                            <Text component={Link} to={"/privacy-policy"}>
                                سياسة الخصوصية
                            </Text>
                        </Group>
                        <Group gap={5}>
                            <Text component={Link} to={"/Payment-delivery-policy"}>
                                سياسة الدفع و التوصيل
                            </Text>
                        </Group>
                        <Group gap={5}>
                            <Text component={Link} to={"/who-are-we"}>
                                من نحن
                            </Text>
                        </Group>
                    </Grid.Col>
                    <Grid.Col span={{base: 6}}>
                        <Title order={5} className={classes.title}>معلومات التواصل</Title>
                        <Group gap={5} mt={20}>
                            <IconMapPinFilled size={20} style={{ color: "#ff914d" }} stroke={1.5} />
                            <Text>
                                الجزائر
                            </Text>
                        </Group>
                        <Group gap={5}>
                            <IconPhoneFilled size={20} style={{ color: "#ff914d" }} stroke={1.5} />
                            <Text component="a" href={"tel:0795110477"} className={classes.link} >
                                0795110477
                            </Text>
                        </Group>
                        {/* <Group gap={5} wrap='nowrap'>
                            <IconMailFilled size={20} style={{ color: "#ff914d" }} stroke={1.5} />
                            <Text component="a" href={"mail:example@gmail.com"} className={classes.link} >
                                example@gmail.com
                            </Text>
                        </Group> */}
                    </Grid.Col>
                </Grid>
            </Container>
        </footer>
    );
}